.tool {
  label {
    font-weight: normal;
    color: $text-muted;
    margin-bottom: 10px;
  }

  table {
    margin-top: 60px;
  }
}

.noty_bar {
  border: 0;
}

.noty_message {
  background-color: $brand-success;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.noty_text {
  color: #ffffff;
  padding-top: 30px;
}
